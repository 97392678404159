@import "../../../styles/global";

$defaultMargin: 20px;

* {
  box-sizing: border-box;
}

.body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #F8EDEB;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100svh;
  width: 100vw;
  overflow: hidden;
}

.header {
  background-color: #007B5E;
  color: white;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  font-family: Unbounded, sans-serif;
  font-size: 18px;
  line-height: 32px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 25px;
}

.container {
  background-color: #F8EDEB;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.container h1 {
  font-size: 24px;
  color: black;
  font-family: Unbounded, sans-serif;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: $defaultMargin;
}

.container p {
  color: #333;
  font-family: Unbounded, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 19.84px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: $defaultMargin $defaultMargin 10svh $defaultMargin;
}

.loginBox {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin: $defaultMargin;
  z-index: 0;
}

.loginBox h2 {
  font-family: Unbounded, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
}

.loginBox label {
  display: block;
  text-align: left;
  margin-bottom: 5px;
  color: black;
  font-family: Unbounded, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 19.84px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 15px;
}

.loginBox .tryLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  margin-top: 15px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.5);

  @for $i from 1 through 100 {
    @if $i <= 2 {
      &.loginTryNumber_#{$i} {
        color: rgba(241, 82, 82, 1);
      }
    } @else {
      &.loginTryNumber_#{$i} {
        color: #B00020;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 1s infinite;
}

.loginBox .input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #000;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
}

.loginBox button {
  background-color: #F4A261;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 100vmax;
  cursor: pointer;
  font-size: 20px;
}

.errorMessage {
  color: #B00020;
  margin-bottom: $defaultMargin;
  font-family: Unbounded, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.84px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.flower {
  transform: translate(50%, -50%);
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
}

.flower img {
  width: 50px;
  height: 50px;
  box-sizing: border-box;
}

.bottomFlowers {
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bottomFlowers img {
  width: 50px;
  height: 50px;
  box-sizing: border-box;
}

.counter {
  font-family: Unbounded, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 22.32px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 100%;
  display: block;
  padding: 10px 0;
}

.slider {
  position: relative;
  width: 100vw;
  height: 50vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout {
  position: absolute;
  top: 10px;
  right: 10px;
  font-family: Unbounded, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 19.84px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.image {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  opacity: 0;

  &.active {
    transition: transform 0.5s;
    opacity: 1;
    transform: translateX(0);
    z-index: 999;
  }

  &.previous {
    transition: transform 0.5s;
    opacity: 1;
    transform: translateX(-110%);

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        z-index: #{$i};
      }
    }
  }

  &.next {
    transition: transform 0.5s;
    opacity: 1;
    transform: translateX(110%);

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        z-index: #{11 - $i};
      }
    }
  }

  &.superior {
    transform: translateX(200%);
  }

  &.inferior {
    transform: translateX(-200%);
  }
}

.arrowBox {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .left {
    transform: rotate(180deg);
    margin-right: 5px;
  }

  .right {
    margin-left: 5px;
  }
}
