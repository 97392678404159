@import "../../../styles/global";

.img {
  width: 100%;

  &__grid {
    margin: 0.625rem 0 0 0;
    height: 400px;
    object-fit: cover;
  }
}

.citation {
  font-family: $fontTaviraj;
  font-size: 2rem;
  font-style: italic;
  font-weight: 500;
  line-height: 2.48rem;
  text-align: center;
  margin: 6.25rem 0;
}

