@import "../../styles/global";

.slider {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  width: 100vw;
  font-family: sans-serif;
  margin: 2rem 0 2rem calc(50% - 50vw);

  &__container {
    animation: loopText 25s infinite linear;

    &__element {
      display: inline-flex;
      padding: 3rem;
      font-size: 4rem;
      font-family: $fontTaviraj;
      font-style: italic;
      border-radius: 100vmax;
      border: 1px solid white;
    }
  }
}


