@import "../../styles/global";

.navigation {
    padding: 6rem 0;
    max-width: $maxWidth;
    margin: 0 auto;
    border-top: 1px solid rgba(white, 0.1);

    @media (max-width: $sm) {
        display: none;
    }

    &__center {
        text-align: center;
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    &__title,
    &__subtitle {
        text-align: center;
        color: white;
    }

    &__title {
        font-family: $fontEpilogue;
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }

    &__subtitle {
        font-family: $fontTaviraj;
        font-size: 1.2rem;
        font-style: italic;
    }

    &__previous, &__next {
        opacity: 0.7;
        transition: opacity $transition-time ease-in-out;

        &:hover {
            opacity: 1;
        }
    }

    &__link {
        display: flex;
        text-decoration: none;
        color: white;
        font-family: $fontEpilogue;
        font-size: 1.2rem;
        opacity: 0.7;
        transition: opacity $transition-time ease-in-out;

        span {
            margin: auto;
        }

        &:hover {
            opacity: 1;
        }
    }

    &__direction {
        position: relative;
        text-align: center;

        &:before {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 1px;
            background: white;
            transform: scaleX(0);
            transition: transform $transition-time ease-in-out;
            transform-origin: right;
        }
    }

    &__previous &__direction {
        &:before {
            transform-origin: right;
        }
    }

    &__next &__direction {
        &:before {
            transform-origin: left;
        }
    }

    &__link:hover &__direction:before {
        transform: scaleX(1);
    }
}