@import "../../../styles/global";

.title {
  font-family: $fontEpilogue;
  font-size: 4.75rem;
  font-weight: 500;
  line-height: 5.7rem;
  text-align: left;
  margin-top: 0;
  padding-bottom: 1.25rem;

  @media (max-width: $sm) {
    font-size: 3.2rem;
    line-height: 3.9rem;
  }
}

.subtitle {
  font-family: $fontEpilogue;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 2.17rem;
  text-align: left;
}

.description {
  font-family: $fontEpilogue;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  margin-top: 5rem;
  margin-bottom: 5rem;

  @media (max-width: $sm) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.link {
  transform: rotate(-8deg);

  @media (max-width: $sm) {
    margin-top: 2rem;
  }
}

.backTo {
  background: none;
  position: fixed;
  bottom: 7vh;
  left: 3vw;
  color: white;
  border: none;
  font-size: 1rem;
  font-family: $fontEpilogue;
  padding-left: 2vw;
  overflow: hidden;

  &:after, &:before {
    content: '';
    position: absolute;
    width: 25%;
    height: 1px;
    left: 0;
    background: white;
    transition: transform 0.3s;
    transform-origin: left;
    top: 50%;
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:after {
    transform: rotate(45deg);
  }

  @media (max-width: $sm) {
    display: none;
  }
}
