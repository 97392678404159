@keyframes scaleBounce {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1.2);
  }
}

@keyframes scaleBack {
  0% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes bubble {
  0% {
    border-radius: 0 50vmax 50vmax 0;
  }

  25% {
    border-radius: 0 80vmax 40vmax 0;
  }

  50% {
    border-radius: 0 40vmax 80vmax 0;
  }

  75% {
    border-radius: 0 85vmax 85vmax 0;
  }

  100% {
    border-radius: 0 50vmax 50vmax 0;
  }
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(15%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


@keyframes loopText {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
