@import "../../styles/global";

.img {
  width: 100%;

  @media (max-width: $sm) {
    image-rendering: crisp-edges;
    image-rendering: -webkit-optimize-contrast;
  }

  &__withEffect {
    transition: clip-path 1s ease;
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }

  &__visible {
    transition: clip-path 1s ease;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
